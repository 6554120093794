<template>
  <div>
    <h4>Sessão de Validação Isolada</h4>
    <p>
      A <b>Sessão de Validação Isolada</b> é uma funcionalidade que permite que
      a validação de ingressos seja feita de forma independente. Isso significa
      que, dentro de uma sessão isolada, somente as validações realizadas ali
      serão consideradas para aprovar ou rejeitar o uso do ingresso.
    </p>
    <h6>O que isso significa:</h6>
    <ul class="mb-4">
      <li>
        Um ingresso validado em uma sessão isolada não interfere no uso do mesmo
        ingresso em outras sessões (sejam elas isoladas ou não).
      </li>
      <li>
        A funcionalidade é útil para situações específicas em que uma validação
        não deve afetar o uso geral do ingresso.
      </li>
    </ul>

    <h6>Exemplos de uso:</h6>
    <ul>
      <li>
        <b>Retirada de produtos:</b> Usar o ingresso para retirar brindes, como
        abadás ou outros itens inclusos na compra.
      </li>
      <li>
        <b>Acessos distintos:</b> Validar o ingresso no portão principal e, em
        seguida, em um setor específico do evento.
      </li>
    </ul>
  </div>
</template>

<script>
export const manifest = {
  id: "paper:admin:isolatedExplanation",
  title: "Sessão de Validação Isolada",
  description: "Entenda como funciona a sessão de validação isolada",
  keywords: ["sessão", "validação", "isolada"],
  relevantIn: [
    "http://localhost:8080/admin/organization/entrance/:id/sessions",
  ],
};

export default {};
</script>
